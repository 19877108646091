module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.eduardoasenjo.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Consultor SEO Eduardo Asenjo","dir":"ltr","lang":"es-es","short_name":"Consultor SEO EA","start_url":"/","background_color":"#f7f0eb","theme_color":"#f57e20","theme_color_in_head":false,"display":"standalone","icon":"src/images/icons/ea-site-512.png","crossOrigin":"use-credentials","icon_options":{"purpose":"any maskable"},"icons":[{"src":"icons/ea-site-72.png","sizes":"72x72","type":"image/png"},{"src":"icons/ea-site-96.png","sizes":"96x96","type":"image/png"},{"src":"icons/ea-site-144.png","sizes":"144x144","type":"image/png"},{"src":"icons/ea-site-192.png","sizes":"192x192","type":"image/png"},{"src":"icons/ea-site-512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"cacheDigest":"907bc8ebebb2794df15540128e5498e6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
